<div class="input-group" style="flex-wrap:nowrap;">
  <input type="text" nbInput [readonly]="IsView" class="status-basic shape-semi-round" (keypress)="validateNumber($event)" (change)="onValuesChange()" [(ngModel)]="pounds"
    [status]="status"
    i18n-placeholder="@@Pounds" placeholder="Pounds" *ngIf="isImperial">
  <div class="input-group-addon input-cm label pounds-kg-label " type="number" inputmode="numeric" *ngIf="isImperial">lbs</div>

  <input type="text" nbInput [readonly]="IsView" class="status-basic shape-semi-round" (keypress)="validateNumber($event)" (change)="onValuesChange()" [(ngModel)]="kgs"
    [status]="status" placeholder="Kg" type="number" inputmode="numeric" *ngIf="!isImperial">
  <div class="input-group-addon input-cm label pounds-kg-label" *ngIf="!isImperial">kg</div>

</div>
